<template>
  <div id="payeeEditOutbounds">
    <header-nav :content="`${$t('pages_main_021')} / ${ editForm.id ? $t('pages_pay_305') : $t('pages_pay_204')}`" />
    <div class="common-card-body">
      <a-steps :current="0" size="small" class="payee-edit-steps">
        <a-step :title="$t('pages_pay_014')" />
        <a-step :title="$t('pages_pay_015')" />
        <a-step :title="$t('pages_pay_016')" />
      </a-steps>
      <div v-if="editForm.id && spinLoading" class="spinning-load">
        <a-spin :tip="$t('common_text_045')" />
      </div>
      <a-form
        v-else
        ref="editRef"
        :model="editForm"
        :rules="editRules"
        class="payee-edit-form ant-round-form"
        layout="vertical"
      >
        <div class="area-title">{{ $t('pages_pay_205') }}</div>
        <div class="area-form">
          <a-form-item
            name="countryCode"
            :required="true"
            :label="$t('pages_pay_094')"
          >
            <a-select
              v-model:value="editForm.countryCode"
              allow-clear
              show-search
              option-label-prop="label"
              option-filter-prop="label"
              :disabled="disabled"
              :placeholder="$t('common_text_005') + $t('pages_pay_094')"
              @change="countryChange"
            >
              <a-select-option
                v-for="item in countries"
                :key="item.id"
                :value="item.country"
                :label="`${item.country} - ${item.countryChineseName}`"
              >
                <span>{{ item.country }} - {{ item.countryChineseName }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="editForm.countryCode"
            name="paymentCurrency"
            style="width: 300px"
            :required="true"
            :label="$t('pages_pay_198')"
          >
            <a-select
              v-model:value="editForm.paymentCurrency"
              allow-clear
              show-search
              :disabled="disabled"
              :placeholder="$t('common_text_005')"
              @change="currencyChange"
            >
              <a-select-option
                v-for="item in currencys"
                :key="item"
                :value="item"
              >
                <img :src="getCountryLogo(item)" class="select-img-country">
                <span>{{ item }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            name="bankAcctType"
            :required="true"
            :label="$t('pages_pay_089')"
            @change="getDynamicParams"
          >
            <a-radio-group
              v-model:value="editForm.bankAcctType"
              button-style="solid"
              :disabled="disabled"
              @change="payeeTypeChange"
            >
              <a-radio-button class="radio-left" value="B2C">{{ $t('pages_pay_207') }}</a-radio-button>
              <a-radio-button class="radio-right" value="B2B">{{ $t('pages_pay_206') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>

        </div>

        <div v-if="editForm.paymentCurrency && paymentModeOpts.length !== 0" class="area-title">{{ $t('pages_pay_211') }}</div>
        <div v-if="editForm.paymentCurrency && paymentModeOpts.length !== 0" class="area-form">
          <a-form-item
            name="paymentMode"
            :required="true"
            :label="$t('pages_pay_078')"
          >
            <div class="settle-list" style="width: 588px" :style="{cursor: disabled ? 'not-allowed' : ''}">
              <div
                v-if="paymentModeOpts.includes('SWIFT')"
                class="settle-card"
                :class="editForm.paymentMode === 'SWIFT' ? 'active' : ''"
                :style="{pointerEvents: disabled ? 'none' : '', cursor: disabled ? 'auto' : 'pointer'}"
                @click="payCardChange('SWIFT')"
              >
                <div class="title">SWIFT</div>
                <div class="content">
                  <div>{{ $t('pages_pay_309') }}</div>
                  <div>{{ $t('pages_pay_310') }}、{{ $t('pages_pay_193') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_311') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
              <div
                v-if="paymentModeOpts.includes('LOCAL')"
                class="settle-card"
                :class="editForm.paymentMode === 'LOCAL' ? 'active' : ''"
                @click="payCardChange('LOCAL')"
              >
                <div class="title">LOCAL</div>
                <div class="content">
                  <div>{{ $t('pages_pay_313') }}</div>
                  <div>{{ $t('pages_pay_314') }}、{{ $t('pages_pay_193') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_315') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
            </div>
          </a-form-item>

          <a-skeleton v-if="formLoading" shape="square" active />

          <a-form-item
            v-for="item in singleDemoList"
            :key="item.fieldName"
            :required="item.required"
            :label="item.title"
            :name="item.fieldName"
          >
            <a-input
              v-if="item.fieldType === 1"
              v-model:value="editForm[item.fieldName]"
              :disabled="disabled"
              :placeholder="$t('common_text_006') + item.title"
            />
            <a-select
              v-if="item.fieldType === 2"
              v-model:value="editForm[item.fieldName]"
              option-filter-prop="label"
              allow-clear
              show-search
              :disabled="disabled"
              :placeholder="$t('common_text_005')"
            >
              <a-select-option
                v-for="i in item.fieldKeyList"
                :key="i.key"
                :value="i.value"
                :label="i.key + i.value"
              >
                <span>{{ i.key }}</span>
              </a-select-option>
            </a-select>
            <!-- upload type -->
            <upload-auto
              v-if="item.fieldType === 3"
              ref="uploadRef"
              v-model:file-content="editForm[item.fieldName]"
              accept=".jpg,.png,.jpeg,.pdf,.bmp,.zip,.rar"
              list-type="picture"
              :explain="$t('pages_pay_342')"
              :max-count="1"
            />
          </a-form-item>
        </div>

        <div class="area-title">{{ $t('pages_pay_213') }}</div>
        <div class="area-form" style="width: 588px">
          <a-form-item :required="true" :label="$t('pages_pay_316')">
            <div style="display: flex">
              <a-form-item
                name="payeeCountryCode"
                style="width: 31%; overflow: auto; padding: 1px 2px"
                :required="true"
              >
                <a-select
                  v-model:value="editForm.payeeCountryCode"
                  allow-clear
                  show-search
                  option-label-prop="label"
                  option-filter-prop="label"
                  :disabled="disabled || (editForm.countryCode === 'UNITED STATES' && editForm.paymentMode === 'LOCAL')"
                  :placeholder="$t('pages_pay_317')"
                >
                  <a-select-option
                    v-for="item in countries"
                    :key="item.id"
                    :value="item.areaCode"
                    :label="`${item.country} - ${item.countryChineseName}`"
                  >
                    <span>{{ item.country }} - {{ item.countryChineseName }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                name="payeeProvince"
                style="width: 31%; margin-left: 3.5%; padding: 1px 2px"
                :required="true"
              >
                <a-select
                  v-if="editForm.countryCode === 'UNITED STATES' && editForm.paymentMode === 'LOCAL'"
                  v-model:value="editForm.payeeProvince"
                  allow-clear
                  show-search
                  :disabled="disabled"
                  :placeholder="$t('pages_pay_318')"
                >
                  <a-select-option
                    v-for="item in provinces"
                    :key="item"
                    :value="item"
                  >
                    <span>{{ item }}</span>
                  </a-select-option>
                </a-select>
                <a-input
                  v-else
                  v-model:value="editForm.payeeProvince"
                  style="height: 32px"
                  allow-clear
                  :disabled="disabled"
                  :placeholder="$t('pages_pay_318')"
                />
              </a-form-item>
              <a-form-item
                name="payeeCity"
                style="width: 31%; margin-left: 3.5%; padding: 1px 2px"
                :required="true"
              >
                <a-input
                  v-model:value="editForm.payeeCity"
                  style="height: 32px"
                  allow-clear
                  :disabled="disabled"
                  :placeholder="$t('pages_pay_319')"
                />
              </a-form-item>
            </div>
            <a-form-item name="payeeAddress" :rules="{required: true, max: 256}">
              <a-textarea
                v-model:value="editForm.payeeAddress"
                class="input-primary"
                allow-clear
                :disabled="disabled"
                :placeholder="$t('pages_pay_320')"
              />
            </a-form-item>
          </a-form-item>

          <a-form-item
            name="payeePostalCode"
            :label="$t('pages_pay_321')"
            :rules="{max: 256}"
          >
            <a-input
              v-model:value="editForm.payeePostalCode"
              allow-clear
              :disabled="disabled"
              :placeholder="$t('pages_pay_321')"
            />
          </a-form-item>
        </div>

        <div v-if="!disabled" class="area-form" style="margin-top: 48px">
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px"
            :loading="loading"
            @click="handleSubmit"
          >{{ $t('common_text_002') }}</a-button>
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px; margin-left: 36px"
            ghost
            @click="handleReset"
          >{{ $t('common_text_040') }}</a-button>
        </div>
        <div v-else class="area-form" style="margin-top: 48px">
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px"
            ghost
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import { ref, reactive, toRefs, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _pay } from '@/api'
import { message } from 'ant-design-vue'
import { getCountryLogo } from '@/util'
import UploadAuto from '@/components/upload-auto'
import i18n from '@/locale'
export default {
  name: 'PayeeEditOutbounds',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const editRef = ref(null)
    const uploadRef = ref(null)
    const state = reactive({
      loading: false,
      formLoading: false,
      spinLoading: false,
      countries: [],
      currencys: [],
      provinces: [],
      disabled: route.query.type && route.query.type === 'detail',
      paymentModeOpts: [],
      singleDemoList: [],
      editForm: {
        id: route.query.payeeId,
        version: '',
        countryCode: null,
        bankAcctType: '',
        paymentCurrency: null,
        paymentMode: '',
        payeeCountryCode: null,
        payeeProvince: null,
        payeeCity: '',
        payeeAddress: '',
        payeePostalCode: ''
      }
    })

    const countryChange = async (e) => {
      countryClear()
      if (e) {
        const res = await _pay.getPayeeCurrencys({ payeeCountry: e })
        if (res.data.succ) state.currencys = res.data.data
        else message.error(res.data.resMsg)
      }
    }

    const currencyChange = () => {
      currencyClear()
    }

    const payeeTypeChange = async (e) => {
      payeeTypeClear()
      const { countryCode, paymentCurrency } = state.editForm
      if (!countryCode || !paymentCurrency) return
      const res = await _pay.getOutPayTypeByType({
        payeeCountry: countryCode,
        payOutCurrency: paymentCurrency,
        debitCurrency: 'USD',
        paymentType: e.target.value
      })
      if (res.data.succ) {
        state.paymentModeOpts = res.data.data
        if (state.paymentModeOpts.length === 1) {
          state.editForm.paymentMode = state.paymentModeOpts[0]
          payCardChange(state.editForm.paymentMode)
        } else {
          getDynamicParams()
        }
      } else {
        message.error(res.data.resMsg)
      }
    }

    const payCardChange = (paymentMode) => {
      state.editForm.paymentMode = paymentMode
      editRef.value.validateFields('paymentMode')
      payCardClear()
      getDynamicParams()
      if (state.editForm.countryCode === 'UNITED STATES' && state.editForm.paymentMode === 'LOCAL') state.editForm.payeeCountryCode = 'US'
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(() => {
          state.loading = true
          const data = Object.assign({}, state.editForm, {})
          if (data.id) {
            data.payeeId = data.id
            return _pay.outboundPayeeUpdate(data)
          }
          return _pay.outboundPayeeAdd(data)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(state.editForm.id ? i18n.global.t('common_text_044') : i18n.global.t('common_text_043'))
            router.back(-1)
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => {
          console.log(err)
          message.error(i18n.global.t('common_text_030'))
          state.loading = false
        })
    }

    const handleReset = () => {
      const { id } = state.editForm
      state.editForm.id = id
      state.editForm.countryCode = null
      countryClear()
      editRef.value.resetFields()
    }

    const getDynamicParams = async () => {
      const { countryCode, bankAcctType, paymentCurrency, paymentMode } = state.editForm
      if (countryCode && bankAcctType && paymentCurrency && paymentMode) {
        state.formLoading = true
        const res = await _pay.getDynamicParameter({ countryCode, bankAcctType, paymentCurrency, paymentMode })
        if (res.data.succ) {
          const { singleDemoList, version } = res.data.data
          state.editForm.version = version
          state.singleDemoList = singleDemoList
          for (const item of singleDemoList) {
            state.editForm[item.fieldName] = item.fieldValue
          }
        } else {
          message.error(res.data.resMsg)
        }
        state.formLoading = false
      }
    }

    const loadPayeeDetail = async () => {
      state.spinLoading = true
      console.log('loadPayeeDetail')
      const res = await _pay.outboundPayeeDetail({ payeeId: state.editForm.id })
      if (res.data.succ) {
        const { data } = res.data
        state.currencys = data.paymentCurrencys || []
        state.paymentModeOpts = data.paymentModes || []
        state.singleDemoList = data.singleDemoList || []
        for (const i in state.editForm) {
          if (i === 'version') state.editForm[i] = data.newVersion
          else state.editForm[i] = data[i]
        }
        for (const item of data.singleDemoList) {
          state.editForm[item.fieldName] = item.fieldValue
        }
        console.log('loadPayeeDetail:', state.editForm)
        nextTick(() => {
          console.log('loadPayeeDetail uploadRef:', state.editForm['relationMaterialPath'], uploadRef.value)
          if (uploadRef.value) {
            if (state.editForm['relationMaterialPath']) {
              uploadRef.value.fileListRefresh(state.editForm['relationMaterialPath'])
            }
          }
        })
      } else {
        message.error(res.data.resMsg)
      }
      state.spinLoading = false
    }

    const loadCountries = async () => {
      const res = await _pay.getOutboundsCountries()
      if (res.data.succ) state.countries = res.data.data
    }

    const loadProvinces = async () => {
      const res = await _pay.outboundPayeeProvince({ payeeCountryCode: 'US' })
      if (res.data.succ) state.provinces = res.data.data
    }

    const countryClear = () => {
      state.currencys = []
      state.editForm.paymentCurrency = null
      currencyClear()
    }

    const currencyClear = () => {
      state.editForm.bankAcctType = ''
      payeeTypeClear()
    }

    const payeeTypeClear = () => {
      state.paymentModeOpts = []
      state.editForm.paymentMode = ''
      payCardClear()
    }

    const payCardClear = () => {
      state.singleDemoList = []
      const { id, countryCode, bankAcctType, paymentCurrency, paymentMode } = state.editForm
      state.editForm = {
        id,
        version: '',
        countryCode,
        bankAcctType,
        paymentCurrency,
        paymentMode,
        payeeCountryCode: null,
        payeeProvince: null,
        payeeCity: '',
        payeeAddress: '',
        payeePostalCode: ''
      }
    }

    const init = async () => {
      await loadCountries()
      await loadProvinces()
      if (state.editForm.id) await loadPayeeDetail()
    }

    init()

    return {
      uploadRef,
      editRef,
      getCountryLogo,
      getDynamicParams,
      countryChange,
      currencyChange,
      payeeTypeChange,
      payCardChange,
      handleReset,
      handleSubmit,
      loadCountries,
      init,
      ...toRefs(state)
    }
  }
  // async mounted() {
  //   await this.init()
  //   // this.$nextTick(() => {
  //   //   console.log('nexttick uploadRef:', this.editForm['relationMaterialPath'], this.$refs.uploadRef)
  //   //   if (this.$refs.uploadRef && this.$refs.uploadRef.fileListRefresh) {
  //   //     if (this.editForm['relationMaterialPath']) {
  //   //       this.$refs.uploadRef.fileListRefresh(this.editForm['relationMaterialPath'])
  //   //     }
  //   //   }
  //   // })
  // }
}
</script>
<style lang="less" scoped>
#payeeEditOutbounds {
  .common-card-body {
    padding: 36px 24px;
    .spinning-load {
      margin-top: 36px;
    }
    .payee-edit-form {
      margin-top: 36px;
      text-align: left;
      padding: 0 36px;
      .area-title {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 6px;
        border-bottom: 1px solid #f0f0f0;
        letter-spacing: 1px;
        margin-top: 24px;
      }
      .area-form {
        width: 500px;
        padding: 0 24px;
        margin-top: 24px;
        .ant-select {
          overflow: inherit;
        }
        .radio-left, .radio-right {
          width: 150px;
          padding: 0 58px;
          height: 35px;
          line-height: 35px;
          border-radius: 25px 0 0 25px;
        }
        .radio-right {
          border-radius: 0 25px 25px 0;
        }
        .input-primary:deep(.ant-input) {
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
